import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { withParamsAndNavigate } from './WithParamsAndNavigate';

class Home extends Component {

  render() {
    return <section>
      <div className="container">
        <div className="home-header py-5 mt-3 text-center">
          <h1 className="fs-2">Ignite Engagement, Spark Connection<br />Digital Tools for Events</h1>
          <p className="lead text-muted py-3">Our platform offers cutting-edge marketing and event tools<br />to create, manage, and enhance virtual experiences that captivate and connect audiences in Puerto Rico.</p>
          <div className="py-3">
            <Link to="/landing/login" className="btn btn-primary">Login <Icon icon="faArrowRight" /></Link>
          </div>
        </div>
      </div>
    </section>;
  }
}

export default withParamsAndNavigate(Home);