import { Link } from "react-router-dom";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import Icon from "../Icon";
import { qreq } from "../../shared/qrequest";
import FormInput from "../FormInput";
import Card from "../Card";
import L from "../Lang";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Pop } from "../PopModal";

class ContactEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            groupInfo: {},
            groupList: []
        };
        this.loadGroups = this.loadGroups.bind(this);
        this.submit = this.submit.bind(this);
        this.submitGroup = this.submitGroup.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.loadGroups();
        if (this.props.params.id) {
            qreq.get('/api/contact/item?id=' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback)
        }
    }

    loadGroups() {
        qreq.get('/api/group/list', j => {
            if (j.list)
                this.setState({ groupList: j.list });
        });
    }

    validatePhone(str) {
        str = str.replace(/[^0-9]/g, '');
        if(str.length < 10)
            return false;
        return true;
    }

    submit(e) {
        if (e) e.preventDefault();
        if(this.state.info.phone && !this.validatePhone(this.state.info.phone)) {
            this.alert('Invalid phone number.');
            return;
        }
        qreq.post('/api/contact/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Saved!');
                this.goBack();
            }
        }, this.unkownErrorCallback);
    }

    submitGroup(e) {
        if (e) e.preventDefault();
        qreq.post('/api/group/save', { ...this.state.groupInfo }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Group saved!');
                this.setState({ showAddGroup: false, groupInfo: {}, info: { ...this.state.info, groupID: j.item.id } });
                console.log('info', { ...this.state.info, groupID: j.item.id });
                this.loadGroups();
            }
        }, this.unkownErrorCallback);
    }

    delete() {
        Pop.showConfirm(<L>Are you sure you want to delete this contact?</L>, 'Delete Contact', () => {
            qreq.post('/api/contact/delete', { ...this.state.info }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Deleted.');
                    this.goBack();
                }
            }, this.unkownErrorCallback);
        });
    }

    render() {
        return <div>
            <Modal isOpen={this.state.showAddGroup}>
                <ModalHeader>
                    Add Group
                </ModalHeader>
                <form onSubmit={this.submitGroup}>
                    <ModalBody>
                        <FormInput model={this.state.groupInfo} name="name" type="text" label="Group Name" labelAsPlaceholder required />
                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                        <button type="button" className="btn btn-secondary" onClick={() => this.setState({ showAddGroup: false })}><Icon icon="faCancel" /> Cancel</button>
                    </ModalFooter>
                </form>
            </Modal>
            <form onSubmit={this.submit}>
                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
                <div className="row align-items-center">
                    <div className="col-md">
                        <Card title="Contact Info">
                            <div className="row">
                                <div className="col-md">
                                    <FormInput model={this.state.info} name="firstName" type="text" label="First Name" labelAsPlaceholder />
                                </div>
                                <div className="col-md">
                                    <FormInput model={this.state.info} name="lastName" type="text" label="Last Name" labelAsPlaceholder />
                                </div>
                            </div>
                            <FormInput model={this.state.info} name="phone" type="tel" label="Phone" labelAsPlaceholder />
                            <FormInput model={this.state.info} name="groupID" type="select" label="Group" labelAsPlaceholder options={this.state.groupList} />
                            <span role="button" className="text-decoration-underline" onClick={() => this.setState({ showAddGroup: true })}>Add new Group</span>
                        </Card>
                    </div>
                    <div className="col-md text-muted">
                        <p className="d-none d-md-block fs-3"><L>Add your contact information and save.</L></p>
                    </div>
                </div>

                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(ContactEdit);