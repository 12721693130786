import configData from '../config.json';
import { getLang } from './GLang';

class _Globals {

    constructor() {
        this.globals = {};
        this.serverUrl = configData.SERVER_URL;
        this.outsidePaths = ['/login', '/user-invite', '/set-password'];
    }

    set(a, b) {
        this.globals[a] = b;
    }
    get(a) {
        return this.globals[a];
    }

    getLang(txt) {
        return getLang(txt);
    }

    isDiffObject(a, b, testInvert) {
        if (a !== b)
            return true;
        if (typeof (a) !== typeof (b))
            return true;
        if (a && b) {
            for (var k in a) {
                if (a[k] !== b[k]) {
                    return true;
                }
                else if (typeof (a[k]) === 'object') {
                    if (!a[k] && !b[k])
                        continue;
                    if (this.isDiffObject(a[k], b[k])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    isEmptyOrWhitespace(a) {
        if (!a || a.length === 0 || a.trim().length === 0)
            return true;
        return false;
    }

    toMDYDateString(d) {
        function pad(n) { return n < 10 ? '0' + n : n };
        return pad(d.getUTCMonth() + 1) + '/' + pad(d.getUTCDate()) + '/' + d.getUTCFullYear();
    }

    toMDYHMLocalDateString(d) {
        function pad(n) { return n < 10 ? '0' + n : n };
        if (!d.getMonth)
            d = new Date(Date.parse(d));
        return pad(d.getMonth() + 1) + '/' + pad(d.getDate()) + '/' + d.getFullYear() + ' ' + pad(d.getHours()) + ':' + pad(d.getMinutes());
    }

    toISODateString(d) {
        function pad(n) { return n < 10 ? '0' + n : n }
        return d.getUTCFullYear() + '-'
            + pad(d.getUTCMonth() + 1) + '-'
            + pad(d.getUTCDate()) + 'T'
            + pad(d.getUTCHours()) + ':'
            + pad(d.getUTCMinutes()) + ':'
            + pad(d.getUTCSeconds()) + 'Z';
    }

    toISOLocalDateString(d) {
        function pad(n) { return n < 10 ? '0' + n : n }
        return d.getFullYear() + '-'
            + pad(d.getMonth() + 1) + '-'
            + pad(d.getDate()) + 'T'
            + pad(d.getHours()) + ':'
            + pad(d.getMinutes()) + ':'
            + pad(d.getSeconds());
    }

    getOutsidePath(loc) {
        var r = null;
        this.outsidePaths.forEach(i => {
            if (loc.length >= i.length && loc.substring(0, i.length) === i) {
                r = i;
            }
        });
        return r;
    }

    isOutsidePath(loc) {
        return (this.getOutsidePath(loc) !== null);
    }

    deepClone(a, exclude) {
        if (!Array.isArray(exclude))
            exclude = [exclude];
        var b = {};
        for (var i in a) {
            if (exclude.indexOf(i) === -1)
                b[i] = a[i];
        }
        return b;
    }

    deepCopy(src, dst, exclude) {
        if (!Array.isArray(exclude))
            exclude = [exclude];
        for (var i in src) {
            if (exclude.indexOf(i) === -1)
                dst[i] = src[i];
        }
    }

    emptyObj(obj, exclude) {
        if (!Array.isArray(exclude))
            exclude = [exclude];
        for (var i in obj) {
            if(exclude.indexOf(i) === -1)
                delete obj[i];
        }
    }

    processResultObject(obj) {
        try {
            if (typeof (obj) === 'object') {
                for (var k in obj) {
                    if (Array.isArray(obj[k])) {
                        obj[k].forEach(i => {
                            this.processResultObject(i);
                        });
                    }
                    else {
                        var s = obj[k];
                        if (s) {
                            if (s.toLowerCase && s === '0001-01-01T00:00:00')
                                obj[k] = this.toISOLocalDateString(new Date());
                            else {
                                if (s.endsWith && !s.endsWith('Z'))
                                    s += 'Z';
                                var v = Date.parse(s);
                                if (s.indexOf && s.indexOf('-') !== -1 && !isNaN(v)) {
                                    var d = new Date(v);
                                    obj[k] = this.toISOLocalDateString(d);
                                }
                                else if (typeof (obj[k]) === 'object') {
                                    this.processResultObject(obj[k]);
                                }
                            }
                        }
                    }
                }
            }
        }
        catch (e) {
            console.log('exception', e);
        }
    }
}

const Globals = new _Globals();

export default Globals;