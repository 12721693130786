import ClientEdit from "./components/Management/ClientEdit";
import ClientList from "./components/Management/ClientList";
import UserEdit from "./components/Management/UserEdit";
import UserList from "./components/Management/UserList";
import AdminBroadcastList from "./components/Messaging/AdminBroadcastList";
import Broadcast from "./components/Messaging/Broadcast";
import ContactEdit from "./components/Messaging/ContactEdit";
import ContactList from "./components/Messaging/ContactList";

const AppRoutes = [
  {
    path: '/console/dashboard',
    element: <Broadcast />
  },
  {
    path: '/console/contact',
    element: <ContactList />
  },
  {
    path: '/console/contact/new',
    element: <ContactEdit />
  },
  {
    path: '/console/contact/edit/:id',
    element: <ContactEdit />
  },
  {
    path: '/console/broadcast',
    element: <Broadcast />
  },

  // Management

  {
    path: '/console/client',
    element: <ClientList />
  },
  {
    path: '/console/client/new',
    element: <ClientEdit />
  },
  {
    path: '/console/client/edit/:id',
    element: <ClientEdit />
  },

  {
    path: '/console/user',
    element: <UserList />
  },
  {
    path: '/console/user/new',
    element: <UserEdit />
  },
  {
    path: '/console/user/edit/:id',
    element: <UserEdit />
  },
  {
    path: '/console/admin/broadcast',
    element: <AdminBroadcastList />
  }
];

export default AppRoutes;
