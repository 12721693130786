import { Link } from "react-router-dom";
import BaseCom from "../BaseCom";
import Card from "../Card";
import FormInput from "../FormInput";
import L from "../Lang";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class WebOptIn extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            submitting: false
        };
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        if (e) e.preventDefault();
        this.success('Thank you for opting in!');
        this.setState({ info: {} });
    }

    render() {
        return <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={this.submit}>
                            <Card title="Opt In for Upcoming Events">
                                <FormInput model={this.state.info} name="email" label="Email" type="email" required labelAsPlaceholder autoComplete="email" />
                                <FormInput model={this.state.info} name="phone" label="Phone Number" type="tel" required labelAsPlaceholder autoComplete="phone" />
                                <p><L>Message and data rates may apply.</L> <L>Message frequency may vary' to set correct expectations for all services.</L> <L>Reply "STOP" to cancel.</L></p>
                                <p><L>By signing up you agree to our <Link to="/landing/terms-of-service">Terms of Service</Link> and <Link to="/landing/privacy-policy">Privacy Policy.</Link>.</L></p>
                                <div className="mt-2 d-grid gap-1">
                                    <button type="submit" className="btn btn-primary">Subscribe to Event Notifications</button>
                                </div>
                            </Card>
                        </form>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default withParamsAndNavigate(WebOptIn);