import { Link } from "react-router-dom";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import Icon from "../Icon";
import { qreq } from "../../shared/qrequest";
import FormInput from "../FormInput";
import Card from "../Card";
import L from "../Lang";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Pop } from "../PopModal";

class ClientEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        if (this.props.params.id) {
            qreq.get('/api/client/item?id=' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback)
        }
    }

    submit(e) {
        if (e) e.preventDefault();
        qreq.post('/api/client/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Saved!');
                this.goBack();
            }
        }, this.unkownErrorCallback);
    }

    delete() {
        Pop.showConfirm(<L>Are you sure you want to delete this client?</L>, 'Delete Client', () => {
            qreq.post('/api/client/delete', { ...this.state.info }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Deleted.');
                    this.goBack();
                }
            }, this.unkownErrorCallback);
        });
    }

    render() {
        return <div>
            <form onSubmit={this.submit}>
                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
                <div className="row align-items-center">
                    <div className="col-md">
                        <Card title="Client Info">
                            <FormInput model={this.state.info} name="name" type="text" label="Client Name" labelAsPlaceholder />
                        </Card>
                    </div>
                    <div className="col-md text-muted">
                        <p className="d-none d-md-block fs-3"><L>Add client information and save.</L></p>
                    </div>
                </div>

                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(ClientEdit);