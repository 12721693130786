import { Link } from "react-router-dom";
import { qreq } from "../../shared/qrequest";
import Alert from "../Alert";
import BaseCom from "../BaseCom";
import Icon from "../Icon";
import LoadingAlert from "../LoadingAlert";
import FormInput from "../FormInput";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import React from "react";

class ContactList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            list: null,
            filter: {}
        };
        this.listRef = React.createRef();
        this.uploaderRef = React.createRef();
        this.load = this.load.bind(this);
        this.uploadContacts = this.uploadContacts.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.search = this.search.bind(this);
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.load();
        document.addEventListener('scroll', this.scrollHandler);
        this.tickInterval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHandler);
        clearInterval(this.tickInterval);
    }

    load(search) {
        qreq.get('/api/contact/list' + (search ? '?search=' + encodeURIComponent(search) : ''), j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    uploadContactsFile(file) {
        this.setState({ importing: true });
        var reader = new FileReader();
        reader.addEventListener('progress', e => {
            var percent = Math.round((e.loaded / e.total) * 100);
        });
        reader.onload = () => {
            var b64data = btoa(reader.result);
            qreq.post('/api/contact/import/vcf', { name: file.name, mimeType: file.type, dataSize: file.size, base64Content: b64data }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Upload success!');
                    this.load();
                }
                this.setState({ importing: false });
            }, () => {
                this.unkownErrorCallback();
                this.setState({ importing: false });
            });
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
            this.setState({ importing: false });
        };
        reader.readAsBinaryString(file);
    }

    uploadContacts(e) {
        e.preventDefault();

        for (var n = 0; n < e.target.files.length; n++) {
            var f = e.target.files[n];
            console.log('Uploading: ' + f.name);
            this.uploadContactsFile(f);
        };

        e.target.value = '';
    }

    scrollHandler(e) {
        if (!this.listRef.current)
            return;
        const bottom = this.listRef.current.getBoundingClientRect().bottom - 50 <= window.innerHeight;
        if (bottom) {
            if (this.state.loadingContacts || this.state.endOfContacts)
                return;
            this.setState({ loadingContacts: true });
            qreq.get('/api/contact/list?page=' + (this.state.page + 1) + (this.state.filter.search ? '&search=' + encodeURIComponent(this.state.filter.search) : ''), j => {
                if (j.list) {
                    if (j.list.length === 0)
                        this.setState({ endOfContacts: true });
                    else {
                        var list = [...this.state.list];
                        list = list.concat(j.list);
                        this.setState({ list: list, page: this.state.page + 1 });
                    }
                }
                else
                    this.alert(j.errorMessage);
                this.setState({ loadingContacts: false });
            }, () => {
                this.setState({ loadingContacts: false });
                this.unkownErrorCallback();
            });
        }
    }

    search(v) {
        this.load(v);
    }

    tick() {
        if(this.currentSearch !== this.state.filter.search) {
            this.currentSearch = this.state.filter.search;
            this.search(this.currentSearch);
        }
    }

    render() {
        return <div>
            <div className="d-none"><input type="file" ref={this.uploaderRef} onChange={this.uploadContacts} /></div>
            <div className="d-flex gap-1 my-2">
                <Link to="/console/contact/new" className="btn btn-primary"><Icon icon="faPlus" /> New Contact</Link>
                <button type="button" onClick={() => this.uploaderRef.current.click()} className="btn btn-secondary"><Icon icon="faUpload" /> Upload Contacts</button>
            </div>
            <FormInput model={this.state.filter} name="search" label="Search contacts..." labelAsPlaceholder type="text" />
            {this.state.list ? <>
                <div className="list-group" ref={this.listRef}>
                    {this.state.list.length === 0 ? <Alert type="dark">You have no contacts.</Alert> : this.state.list.map(i => <Link key={i.id} to={'/console/contact/edit/' + i.id} className="list-group-item">
                        <h4>{i.name}</h4>
                        <tel>{i.phone}</tel>
                    </Link>)}
                </div>
                {this.state.loadingContacts ? <LoadingAlert /> : ''}
            </> : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(ContactList);