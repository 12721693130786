import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import Icon from "../Icon";
import { qreq } from "../../shared/qrequest";
import FormInput from "../FormInput";
import Card from "../Card";
import L from "../Lang";
import { Pop } from "../PopModal";

class UserEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            clientList: []
        };
        this.loadClients = this.loadClients.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.loadClients();
        if (this.props.params.id) {
            qreq.get('/api/user/item?id=' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback)
        }
    }

    loadClients() {
        qreq.get('/api/client/list', j => {
            if(j.list)
                this.setState({ clientList: j.list });
        });
    }

    submit(e) {
        if (e) e.preventDefault();
        qreq.post('/api/user/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Saved!');
                this.goBack();
            }
        }, this.unkownErrorCallback);
    }

    delete() {
        Pop.showConfirm(<L>Are you sure you want to delete this user?</L>, 'Delete User', () => {
            qreq.post('/api/user/delete', { ...this.state.info }, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Deleted.');
                    this.goBack();
                }
            }, this.unkownErrorCallback);
        });
    }

    render() {
        return <div>
            <form onSubmit={this.submit}>
                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
                <div className="row align-items-center">
                    <div className="col-md">
                        <Card title="User Info">
                        <div className="row">
                                <div className="col-md">
                                    <FormInput model={this.state.info} name="firstName" type="text" label="First Name" labelAsPlaceholder required />
                                </div>
                                <div className="col-md">
                                    <FormInput model={this.state.info} name="lastName" type="text" label="Last Name" labelAsPlaceholder required />
                                </div>
                            </div>
                            <FormInput model={this.state.info} name="email" type="email" label="Email" labelAsPlaceholder required />
                            <FormInput model={this.state.info} name="phone" type="tel" label="Phone" labelAsPlaceholder />
                            <FormInput model={this.state.info} name="clientID" type="select" label="Client" labelAsPlaceholder options={this.state.clientList} />
                        </Card>
                    </div>
                    <div className="col-md text-muted">
                        <p className="d-none d-md-block fs-3"><L>Add user information and save.</L></p>
                    </div>
                </div>

                <div className="d-flex gap-2">
                    <button type="submit" className="btn btn-success"><Icon icon="faSave" /> Save</button>
                    <button type="button" onClick={this.goBack} className="btn btn-secondary"><Icon icon="faArrowLeft" /> Go Back</button>
                    {this.state.info.id ? <div className="flex-fill text-end">
                        <button type="button" onClick={this.delete} className="btn btn-danger"><Icon icon="faTrash" /> Delete</button>
                    </div> : ''}
                </div>
            </form>
        </div>;
    }
}

export default withParamsAndNavigate(UserEdit);