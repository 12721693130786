import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Outlet } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);
    this.state = {
      showNavbar: false
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar(on) {
    this.setState({ showNavbar: on });
  }

  render() {
    return (
      <div className={this.state.showNavbar ? '' : 'app-navbar-hidden'}>
        <ScrollToTop />
        <NavMenu toggleNavbar={this.toggleNavbar} showNavbar={this.state.showNavbar} />
        <div className="app-body">
          <Outlet />
        </div>
      </div>
    );
  }
}
