import { Link } from "react-router-dom";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import Icon from "../Icon";
import DataTable from "../DataTable";
import LoadingAlert from "../LoadingAlert";
import { qreq } from "../../shared/qrequest";

class ClientList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: null
        };
    }

    componentDidMount() {
        qreq.get('/api/client/list', j => {
            if(j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        return <div>
            <div className="my-2 btn-group">
                <Link to="/console/client/new" className="btn btn-primary"><Icon icon="faPlus" /> New Client</Link>
            </div>
            {this.state.list ? <DataTable columns={[
                { icon: 'Edit', onClick: row => {
                    this.props.navigate('/console/client/edit/' + row.id);
                } },
                { name: 'name', title: 'Client '}
            ]} data={this.state.list} /> : <LoadingAlert />}
        </div>;
    }
}

export default withParamsAndNavigate(ClientList);