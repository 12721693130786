import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import BaseCom from '../BaseCom';
import { withParamsAndNavigate } from '../WithParamsAndNavigate';
import Card from '../Card';
import FormInput from '../FormInput';
import { qreq } from '../../shared/qrequest';

class ContactPage extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            submitting: false
        };
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        if (e) e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/support/message', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else
                this.setState({ success: true, info: {} });
            this.setState({ submitting: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ submitting: false });
        });
    }

    render() {
        return <section>
            <div className="container">
                <div className="home-header py-5 mt-3 text-center">
                    <h1 className="fs-2">Get in Touch</h1>
                    <p className="lead text-muted py-3">Connect with us and get more information about our innovative marketing platform,<br />and stay informed about upcoming events by subscribing to our notifications</p>
                </div>
            </div>
            <div className="contact-form container" style={{ maxWidth: '800px' }}>
                {this.state.success ? <h4>Thank you for contacting us!</h4> :
                <form onSubmit={this.submit}>
                    <Card title="Contact Us">
                        <FormInput model={this.state.info} name="name" label="Your full name" type="text" labelAsPlaceholder required />
                        <FormInput model={this.state.info} name="email" label="Email" type="email" labelAsPlaceholder required />
                        <FormInput model={this.state.info} name="phone" label="Phone" type="tel" labelAsPlaceholder required />
                        <FormInput model={this.state.info} name="message" label="Message" type="textarea" labelAsPlaceholder required />
                        <div className="mt-2 d-grid gap-1">
                            <button type="submit" className="btn btn-primary" disabled={this.state.submitting}>Send us a message!</button>
                        </div>
                    </Card>
                </form>}
            </div>
        </section>;
    }
}

export default withParamsAndNavigate(ContactPage);