import { Link, Outlet } from "react-router-dom";
import BaseCom from "../BaseCom";
import ScrollToTop from "../ScrollToTop";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import WebNavBar from "./WebNavBar";
import './WebLayout.css';

class WebLayout extends BaseCom {
  
  render() {
    return <div className="web" style={{ backgroundImage: 'url(\'/assets/img/bg.jpg\')' }}>
      <ScrollToTop />
      <WebNavBar />
      <div className="web-body">
        <Outlet />
      </div>
      <footer>
        <div className="container">
          <div className="p-3 text-center">
            Copyright &copy; <a href="https://www.solusek.com" target="_blank" rel="noreferrer">Solusek Systems</a>. All Rights Reserved
            | <Link to="/landing/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </div>;
  }
}

export default withParamsAndNavigate(WebLayout);