import React from 'react';
import BaseComponent from '../components/BaseCom';
import FormInput from '../components/FormInput';
import { withParamsAndNavigate } from '../components/WithParamsAndNavigate';
import { qreq } from '../shared/qrequest';
import Globals from '../shared/Globals';
import Auth from '../shared/Auth';
import { Link } from 'react-router-dom';

class Login extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            credentials: {},
            submitting: false
        };
        this.submit = this.submit.bind(this);
        this.setUser = props.setUser;
    }

    componentDidMount() {
        Auth.getLogin(u => {
            if (u)
                this.props.navigate('/console/dashboard');
            else
                this.setState({ loaded: true });
        })
    }

    submit(e) {
        e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/auth/login', this.state.credentials, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else if (j.item) {
                Globals.set('user', j.item);
                this.props.navigate('/console/dashboard');
            }
            this.setState({ submitting: false });
        }, e => {
            this.alert('An unkown error ocurred. Login service might be down.');
            this.setState({ submitting: false });
        });
    }

    render() {
        if (!this.state.loaded)
            return <></>;
        return <div className="c-login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5">
                        <div className="p-4">
                            <div className="bg-white p-4 rounded-3">
                                <form onSubmit={this.submit}>
                                    <h4>Login</h4>
                                    <hr />
                                    <FormInput model={this.state.credentials} name="email" label="Email Address" type="text" required={true} labelAsPlaceholder={true} autoComplete="username" />
                                    <FormInput model={this.state.credentials} name="password" label="Password" type="password" required={true} labelAsPlaceholder={true} autoComplete="current-password" />
                                    <div className="d-grid mt-4">
                                        <button type="submit" className="btn btn-primary" disabled={this.state.submitting}>Login</button>
                                    </div>
                                    <div className="my-2 text-center"><Link to="/landing/forgot-password">I forgot my password</Link></div>
                                </form>
                                {this.state.error ? (<div className="alert alert-danger mt-2">{this.state.error}</div>) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(Login);