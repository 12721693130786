import BaseCom from "./BaseCom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class Index extends BaseCom {
    componentDidMount() {
        setTimeout(() => {
            this.props.navigate('/landing/index');
        }, 100);
    }

    render() {
        return <></>;
    }
}

export default withParamsAndNavigate(Index);