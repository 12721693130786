import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import BaseCom from '../BaseCom';
import { withParamsAndNavigate } from '../WithParamsAndNavigate';

class Services extends BaseCom {

  render() {
    return <section>
      <div className="container">
        <div className="home-header py-5 mt-3 text-center">
          <h1 className="fs-2">Our Services</h1>
          <p className="lead text-muted py-3">Ignite Audience Engagement and Elevate Your Business<br />with Our Cutting-Edge Marketing Platform!</p>
        </div>
      </div>
      <div className="services container" style={{ maxWidth: '800px'}}>
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              <div className="p-2 fs-2 text-primary">
                <Icon icon="faCalendar"  />
              </div>
              <div className="flex-fill p-2">
                <h5>Event Management Capabilities</h5>
                <p>Provides tools to create, manage, and enhance virtual events effectively.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex">
              <div className="p-2 fs-2 text-primary">
                <Icon icon="faMemory" />
              </div>
              <div className="flex-fill p-2">
                <h5>Virtual Experience Enhancement</h5>
                <p>Aims to captivate and connect audiences through immersive virtual experiences.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex">
              <div className="p-2 fs-2 text-primary">
                <Icon icon="faMountainSun" />
              </div>
              <div className="flex-fill p-2">
                <h5>Focused on Puerto Rico</h5>
                <p>Specifically caters to the needs and audiences within Puerto Rico.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex">
              <div className="p-2 fs-2 text-primary">
                <Icon icon="faFaceSmile" />
              </div>
              <div className="flex-fill p-2">
                <h5>User-Friendly Interface</h5>
                <p>Features a straightforward login process for easy access to services.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
  }
}

export default withParamsAndNavigate(Services);