import React from "react";
import { qreq } from "../../shared/qrequest";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class PrivacyPolicy extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            html: ''
        };
        this.htmlRef = React.createRef();
    }

    componentDidMount() {
        qreq.localget('/const/privacy-policy.html', j => {
            this.htmlRef.current.innerHTML = j;
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container" ref={this.htmlRef}></div>;
    }
}

export default withParamsAndNavigate(PrivacyPolicy);