import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, NavLink as DomNavLink } from 'react-router-dom';
import './NavMenu.css';
import Auth from '../shared/Auth';
import Icon from './Icon';
import L from './Lang';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  componentDidMount() {
    Auth.getLogin(u => this.setState({ user: u }));
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return <><header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <NavbarBrand tag={Link} to="/">Kraber Console</NavbarBrand>
        <NavbarToggler onClick={() => this.props.toggleNavbar(!this.props.showNavbar)} className="mr-2" />
      </Navbar>
    </header>
      {this.state.user ? <div className={'app-nav ' + (this.props.showNavbar ? 'd-block' : 'd-none d-md-block')}>
        <div className="app-nav-body">
          <div className="app-nav-menu-title">
            <L>Menu</L>
          </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <DomNavLink to="/console/broadcast" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon="faBullhorn" />
                <span><L>Broadcast</L></span>
              </DomNavLink>
            </li>
            <li className="nav-item">
              <DomNavLink to="/console/contact" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon="faAddressBook" />
                <span><L>Contacts</L></span>
              </DomNavLink>
            </li>
          </ul>
          {this.state.user.roles.indexOf('admin') >= 0 ? <>
            <div className="app-nav-menu-title">
              <L>Administration</L>
            </div>
            <ul className="navbar-nav">
            <li className="nav-item">
                <DomNavLink to="/console/admin/broadcast" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                  <Icon icon="faTable" />
                  <span><L>Pending Broadcasts</L></span>
                </DomNavLink>
              </li>
              <li className="nav-item">
                <DomNavLink to="/console/client" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                  <Icon icon="faCashRegister" />
                  <span><L>Clients</L></span>
                </DomNavLink>
              </li>
              <li className="nav-item">
                <DomNavLink to="/console/user" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                  <Icon icon="faUsers" />
                  <span><L>Users</L></span>
                </DomNavLink>
              </li>
            </ul>
          </> : ''}
          <div className="app-nav-menu-title">
              <L>Session</L>
            </div>
          <ul className="navbar-nav">
            <li className="nav-item">
              <DomNavLink to="/logout" className="nav-link" onClick={() => this.props.toggleNavbar(false)}>
                <Icon icon="faArrowRightFromBracket" />
                <span><L>Logout</L></span>
              </DomNavLink>
            </li>
          </ul>
        </div>
      </div> : ''}
    </>
      ;
  }
}
