import { Link, NavLink } from "react-router-dom";
import BaseCom from "../BaseCom";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";
import { NavbarToggler } from "reactstrap";

class WebNavBar extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            showNavbar: false
        };
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.scroll = this.scroll.bind(this);
    }


    componentDidMount() {
        document.addEventListener('scroll', this.scroll);
        this.scroll();
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scroll);
    }

    toggleNavbar(on) {
        this.setState({ showNavbar: on });
    }


    scroll() {
        this.setState({ scrolled: window.scrollY > 70 });
    }

    render() {
        return <nav className={'web-navbar navbar navbar-expand-lg navbar-landing fixed-top' + (this.state.scrolled ? ' scrolled' : '')} id="navbar">
            <div className="container">
                <Link to="/landing/index" className="navbar-brand">
                    <img src="/assets/img/logo.png" className="card-logo" alt="logo" height="20" />
                </Link>
                <NavbarToggler onClick={() => this.toggleNavbar(!this.state.showNavbar)} className="fs-20 text-body" />

                <div className={'collapse navbar-collapse' + (this.state.showNavbar ? ' show' : '')} id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                        <li className="nav-item">
                            <NavLink to="/landing/index" className="nav-link active">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/landing/services" className="nav-link">Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/landing/contact" className="nav-link">Contact</NavLink>
                        </li>
                    </ul>

                    <div className="">
                        <NavLink to="/landing/login" className="btn btn-link fw-medium text-decoration-none text-body d-none">Log in</NavLink>
                        <NavLink to="/landing/login" className="btn btn-primary">Log In</NavLink>
                    </div>
                </div>

            </div>
        </nav>;
    }
}

export default withParamsAndNavigate(WebNavBar);