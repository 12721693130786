import { Link } from "react-router-dom";
import BaseCom from "../BaseCom";
import Card from "../Card";
import FormInput from "../FormInput";
import L from "../Lang";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class WebOptOut extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            submitting: false,
            phone: 'PLACEHOLDER'
        };
        this.submit = this.submit.bind(this);
    }

    submit(e) {
        if (e) e.preventDefault();
        this.success('Sorry to see you go!');
        this.setState({ info: {} });
    }

    render() {
        return <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <form onSubmit={this.submit}>
                        <Card title="Opt Out from Notifications">
                            <p>You are about to opt-out <strong>{this.state.phone}</strong> from receiving phone notifications. Click the button below to confirm.</p>
                            <div className="mt-2 d-grid gap-1">
                                <button type="submit" className="btn btn-primary">Opt-out from Event Notifications</button>
                            </div>
                        </Card>
                        </form>
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default withParamsAndNavigate(WebOptOut);