import Globals from "../../shared/Globals";
import { qreq } from "../../shared/qrequest";
import Alert from "../Alert";
import BaseCom from "../BaseCom";
import Card from "../Card";
import FormInput from "../FormInput";
import Icon from "../Icon";
import L from "../Lang";
import LoadingAlert from "../LoadingAlert";
import { Pop } from "../PopModal";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class Broadcast extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            pendingList: null,
            groupList: []
        };
        this.submit = this.submit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.loadPending = this.loadPending.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/group/list', j => {
            if (j.list)
                this.setState({ groupList: j.list });
        });
        this.loadPending(); 
    }

    loadPending() {
        qreq.get('/api/broadcast/list/pending', j => {
            if (j.list)
                this.setState({ pendingList: j.list });
        });
    }

    cancel(item) {
        Pop.showConfirm(<L>Are you sure you want to cancel this broadcast?</L>, 'Cancel', () => {
            qreq.post('/api/broadcast/cancel', item, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Broadcast cancelled.');
                    this.loadPending();
                }
            }, this.unkownErrorCallback);
        });
    }

    submit(e) {
        if (e) e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/broadcast/send', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Broadcast queued successfully. Pending review from administration.');
                this.setState({ info: {} });
                this.loadPending();
            }
            this.setState({ submitting: false });
        }, () => {
            this.unkownErrorCallback();
            this.setState({ submitting: false });   
        });
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <Card title="Broadcast">
                    <FormInput model={this.state.info} name="content" label="Message here..." labelAsPlaceholder type="textarea" rows="5" required />
                    <div className="d-grid gap-1">
                        <button type="submit" className="btn btn-lg btn-primary" disabled={this.state.submitting}><Icon icon="faBullhorn" /> <L>Broadcast</L></button>
                    </div>
                </Card>
            </form>
            <Card title="Pending Broadcasts">
                {this.state.pendingList ? <div className="list-group">
                    {this.state.pendingList.length === 0 ? <Alert type="secondary">There are no pending broadcasts.</Alert> : this.state.pendingList.map(i => <div key={i.id} className="list-group-item">
                        <p className="lead my-1">{i.content}</p>
                        <i className="d-block mb-3">{Globals.toMDYHMLocalDateString(i.createdDate)}</i>
                        <button type="button" className="btn btn-danger" onClick={() => this.cancel(i)}><Icon icon="faCancel" /> Cancel</button>
                    </div>)}
                </div> : <LoadingAlert />}
            </Card>
        </div>
    }
}

export default withParamsAndNavigate(Broadcast);