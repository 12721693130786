import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import E404 from './components/E404';
import Login from './components/Login';
import AppRoutes from './AppRoutes';
import { PopModal } from './components/PopModal';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import './custom.css';
import 'react-toastify/dist/ReactToastify.css';
import Logout from './components/Logout';
import WebLayout from './components/Web/WebLayout';
import Broadcast from './components/Messaging/Broadcast';
import Index from './components/Index';
import Home from './components/Home';
import WebOptIn from './components/Web/WebOptIn';
import WebOptOut from './components/Web/WebOptOut';
import Services from './components/Web/Services';
import ContactPage from './components/Web/ContactPage';
import PrivacyPolicy from './components/Web/PrivacyPolicy';
import ForgotPassword from './components/ForgotPassword';
import SetPassword from './components/SetPassword';



export default class App extends Component {
    static displayName = App.name;

    render() {
        return (<>
            <PopModal />
            <ToastContainer position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <BrowserRouter>
                <Routes>
                    <Route index element={<Index />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/console" element={<Layout />}>
                        <Route index element={<Broadcast />} />
                        {AppRoutes.map(i => <Route key={i.path} path={i.path} element={i.element} />)}
                    </Route>
                    <Route path="/landing" element={<WebLayout />}>
                        <Route index element={<Home />} />
                        <Route path="/landing/index" element={<Home />} />
                        <Route path="/landing/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/landing/services" element={<Services />} />
                        <Route path="/landing/optin" element={<WebOptIn />} />
                        <Route path="/landing/optout" element={<WebOptOut />} />
                        <Route path="/landing/contact" element={<ContactPage />} />
                        <Route path="/landing/login" element={<Login />} />
                        <Route path="/landing/forgot-password" element={<ForgotPassword />} />
                        <Route path="/landing/reset-password/:code" element={<SetPassword />} />
                        <Route path="*" element={<E404 />} />
                    </Route>
                    <Route path="*" element={<E404 />} />
                </Routes>
            </BrowserRouter>
            </>
        );
    }
}
