import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Globals from "../../shared/Globals";
import { qreq } from "../../shared/qrequest";
import Alert from "../Alert";
import BaseCom from "../BaseCom";
import Card from "../Card";
import FormInput from "../FormInput";
import Icon from "../Icon";
import L from "../Lang";
import LoadingAlert from "../LoadingAlert";
import { Pop } from "../PopModal";
import { withParamsAndNavigate } from "../WithParamsAndNavigate";

class AdminBroadcastList extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            pendingList: null,
            groupList: []
        };
        this.edit = this.edit.bind(this);
        this.submitEdit = this.submitEdit.bind(this);
        this.approve = this.approve.bind(this);
        this.cancel = this.cancel.bind(this);
        this.loadPending = this.loadPending.bind(this);
    }

    componentDidMount() {
        this.loadPending();
    }

    loadPending() {
        qreq.get('/api/broadcast/admin/list/pending', j => {
            if (j.list)
                this.setState({ pendingList: j.list });
        });
    }

    edit(item) {
        this.setState({ info: { ...item }, showEditModal: true });
    }

    approve(item) {
        Pop.showConfirm(<><p><L>Are you sure you want to</L> <span className="text-success"><L>approve</L></span> <L>this broadcast?</L></p>
            <p>{item.content}</p>
        </>, 'Approve', () => {
            qreq.post('/api/broadcast/admin/approve', item, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Broadcast approved.');
                    this.loadPending();
                }
            }, this.unkownErrorCallback);
        });
    }

    cancel(item) {
        Pop.showConfirm(<><p><L>Are you sure you want to</L> <span className="text-danger"><L>cancel</L></span> <L>this broadcast?</L></p>
            <p>{item.content}</p>
        </>, 'Cancel', () => {
            qreq.post('/api/broadcast/admin/cancel', item, j => {
                if (j.errorCode)
                    this.alert(j.errorMessage);
                else {
                    this.success('Broadcast cancelled.');
                    this.loadPending();
                }
            }, this.unkownErrorCallback);
        });
    }

    submitEdit(e) {
        if (e) e.preventDefault();
        console.log(this.state.info);
        qreq.post('/api/broadcast/admin/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Broadcast message edited successfully.');
                this.setState({ info: {}, showEditModal: false });
                this.loadPending();
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <div className="container-fluid">
            <Modal isOpen={this.state.showEditModal} toggle={() => this.setState({ showEditModal: false })}>
                <form onSubmit={this.submitEdit}>
                    <ModalHeader>
                        <L>Edit Broadcast</L>
                    </ModalHeader>
                    <ModalBody>
                        <FormInput model={this.state.info} name="content" label="Message here..." labelAsPlaceholder type="textarea" rows="5" />
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-grid gap-1">
                            <button type="submit" className="btn btn-primary"><Icon icon="faSave" /> <L>Save</L></button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>
            <Card title="Admin - Pending Broadcasts">
                {this.state.pendingList ? <div className="list-group">
                    {this.state.pendingList.length === 0 ? <Alert type="secondary">There are no pending broadcasts.</Alert> : this.state.pendingList.map(i => <div key={i.id} className="list-group-item">
                        <p className="lead my-1">{i.content}</p>
                        <i className="d-block mb-3">{Globals.toMDYHMLocalDateString(i.createdDate)}</i>
                        <div className="d-flex gap-1">
                            <button type="button" className="btn btn-success" onClick={() => this.approve(i)}><Icon icon="faCheck" /> Approve</button>
                            <button type="button" className="btn btn-secondary" onClick={() => this.edit(i)}><Icon icon="faEdit" /> Edit</button>
                            <button type="button" className="btn btn-danger" onClick={() => this.cancel(i)}><Icon icon="faCancel" /> Cancel</button>
                        </div>
                    </div>)}
                </div> : <LoadingAlert />}
            </Card>
        </div>
    }
}

export default withParamsAndNavigate(AdminBroadcastList);